import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";

const OrderService = {
    getSelectValues(){
        return ApiService.get(API_OMS_URL+"/order/select-values", {
            headers: ApiService.getHeader()
        });
    },
    searchCity(search){
        let params = {search : search};
        return ApiService.get(API_OMS_URL+"/order/search-city", {
            headers: ApiService.getHeader(),
            params : params
        });
    },
    searchLocation(cityId, orderId){
        let params = {city_id : cityId, order_id : orderId};
        return ApiService.get(API_OMS_URL+"/order/search-locations", {
            headers: ApiService.getHeader(),
            params : params
        });
    },
    searchProducts(search, locationId){
        let params = {search : search, location_id : locationId};
        return ApiService.get(API_OMS_URL+"/order/search-products", {
            headers: ApiService.getHeader(),
            params : params
        });
    },
    getOrder(orderId){
        return ApiService.get(API_OMS_URL+"/order", {
            headers: ApiService.getHeader(),
            params : {id : orderId}
        });
    },
    getOrderBasketPrice(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/basket/price", {
            headers: ApiService.getHeader(),
        });
    },
    saveOrderBasketPrice(orderId, products){
        return ApiService.put(API_OMS_URL+"/order/"+orderId+"/basket/price", {products : products},{
            headers: ApiService.getHeader(),
        });
    },
    updateQuantityProduct(params){
        return ApiService.put(API_OMS_URL+"/order/"+params['order_id']+"/product/"+params['product_id']+"/quantity", {quantity : params['quantity']},{
            headers: ApiService.getHeader(),
        });
    },
    filContactUser(phone){
        return ApiService.get(API_OMS_URL+"/order/fill-contact", {
            headers: ApiService.getHeader(),
            params : {phone : phone}
        });
    },
    getProblems(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/problems", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getOnOrderProducts(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/products/on-order", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getProblemsXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/order/problems/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getLogistic(filter = {}, location = ''){
        return ApiService.get(API_OMS_URL+"/order/logistic", {
            headers: ApiService.getHeader(),
            params : {filter : filter, location : location}
        });
    },
    getLogisticMove(filter = {}, location = ''){
        return ApiService.get(API_OMS_URL+"/order/logistic/move", {
            headers: ApiService.getHeader(),
            params : {filter : filter, location : location}
        });
    },
    getLogisticDoDoma(filter = {}){
        return ApiService.get(API_OMS_URL+"/order/logistic/dodoma", {
            headers: ApiService.getHeader(),
            params : {filter : filter}
        });
    },
    getLogisticFranchisees(filter = {}, city){
        return ApiService.get(API_OMS_URL+"/order/logistic/franchisees", {
            headers: ApiService.getHeader(),
            params : {filter : filter, city : city}
        });
    },
    getLogisticEvropochta(filter = {}){
        return ApiService.get(API_OMS_URL+"/order/logistic/evropochta", {
            headers: ApiService.getHeader(),
            params : {filter : filter}
        });
    },
    updateContactOrder(orderId, payload){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/contact', {contact : payload}, {
            headers: ApiService.getHeader(),
        })
    },
    updateCommentOrder(orderId, data){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/comment', {comment : data.comment, comment_for_logist : data.comment_for_logist}, {
            headers: ApiService.getHeader(),
        })
    },
    addProduct(orderId, productId, quantity = 1){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/product-add', {quantity : quantity, product_id : productId }, {
            headers: ApiService.getHeader(),
        })
    },
    create(payload){
        return ApiService.post(API_OMS_URL+'/order', payload, {
            headers: ApiService.getHeader(),
        })
    },
    updateDeliveryOrder(orderId, payload){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/delivery', {delivery : payload}, {
            headers: ApiService.getHeader(),
        })
    },
    updatePaymentOrder(orderId, paymentId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/payment', {payment_id : paymentId}, {
            headers: ApiService.getHeader(),
        })
    },
    cancel(orderId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/cancel', {}, {
            headers: ApiService.getHeader(),
        })
    },
    changeStatus(orderId, statusId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/status', {status_id : statusId}, {
            headers: ApiService.getHeader(),
        })
    },
    resetDateCalc(orderId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/reset-date-calc', {}, {
            headers: ApiService.getHeader(),
        })
    },
    deleteProduct(orderId, productId){
        return ApiService.delete(API_OMS_URL+'/order/'+orderId+'/product/'+ productId, {
            headers: ApiService.getHeader(),
        })
    },
    additionInfo(){
        return ApiService.get(API_OMS_URL+"/order/additional-info", {
            headers: ApiService.getHeader(),
        });
    },
    updateCard(orderId, payload){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/card', {card : payload['card'], bonus_wanted : payload['bonus_wanted']}, {
            headers: ApiService.getHeader(),
        })
    },
    deleteCard(orderId){
        return ApiService.delete(API_OMS_URL+'/order/'+orderId+'/card', {
            headers: ApiService.getHeader(),
        })
    },
    searchOrders(query){
        return ApiService.get(API_OMS_URL+"/order/search", {
            headers: ApiService.getHeader(),
            params : {query : query}
        });
    },
    createShipments(orderId){
        return ApiService.post(API_OMS_URL+'/order/'+orderId+'/shipments', {}, {
            headers: ApiService.getHeader(),
        })
    },
    logisticXlsx(date = null, location = '', type = ''){
        return ApiService.post(API_OMS_URL+'/order/logistic/xlsx', {date : date, location : location, type : type}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    logisticXlsxDoDoma(date = null, isOnly = false){
        return ApiService.post(API_OMS_URL+'/order/logistic/dodoma/xlsx', {date : date, is_only_new : isOnly}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    logisticXlsxFranchisees(date = null, isOnly = false, city  = null){
        return ApiService.post(API_OMS_URL+'/order/logistic/franchisees/xlsx', {date : date, is_only_new : isOnly, city : city}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    logisticMoveXlsx(date = null, location = '', type = 1){
        return ApiService.post(API_OMS_URL+'/order/logistic/move/xlsx', {date : date, location : location, type : type}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    logisticMoveMinskXlsx(date = null, store_id_from = 0){
        return ApiService.post(API_OMS_URL+'/order/logistic/move/minsk/xlsx', {date : date, store_id_from : store_id_from}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    logisticMoveUpdate(date = null, location = ''){
        return ApiService.post(API_OMS_URL+'/order/logistic/move', {date : date, location : location}, {
            headers: ApiService.getHeader(),
        })
    },
    updateCoupon(orderId, coupon = ""){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/coupon', {coupon : coupon}, {
            headers: ApiService.getHeader(),
        })
    },
    allocation(orderId){
        return ApiService.get(API_OMS_URL+"/order/allocation", {
            headers: ApiService.getHeader(),
            params : {order_id : orderId}
        });
    },
    setAllocation(orderId, type){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/allocation', {type : type}, {
            headers: ApiService.getHeader(),
        })
    },
    getUserOrders(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/user", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getOrdersTransactions(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/transactions/list", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getAllOrders(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/all", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getOrderInstallment(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/installment", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getReportDeliveryPrice(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/reports/delivery/price", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    updateDateDelivery(orderId, dateNew){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/date-delivery', {date : dateNew}, {
            headers: ApiService.getHeader(),
        })
    },
    updateDateDoDoma(orderId, dateNew){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/date-dodoma', {date : dateNew}, {
            headers: ApiService.getHeader(),
        })
    },
    updateDateFranchisees(orderId, dateNew){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/date-franchisees', {date : dateNew}, {
            headers: ApiService.getHeader(),
        })
    },
    updateDeliveryInterval(orderId, intervalId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/interval', {interval_id : intervalId}, {
            headers: ApiService.getHeader(),
        })
    },
    getReportDeliveryPriceXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/order/reports/delivery/price/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getCancelVariants(){
        return ApiService.get(API_OMS_URL+"/order/cancel/variants", {
            headers: ApiService.getHeader()
        });
    },
    getCancelAdditionalVariants(){
        return ApiService.get(API_OMS_URL+"/order/cancel-additional/variants", {
            headers: ApiService.getHeader()
        });
    },
    cancelWithVariants(orderId, form){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/cancel/new', form, {
            headers: ApiService.getHeader(),
        })
    },
    updateOnOrderProductColumn(itemId, form){
        return ApiService.put(API_OMS_URL+'/order/products/on-order/'+itemId+'/column', form, {
            headers: ApiService.getHeader(),
        })
    },
    getOrderPdf(orderId, type, additional = {}){
        return ApiService.post(API_OMS_URL+'/order/'+orderId+'/pdf', {type : type, additional : additional}, {
            headers: ApiService.getHeader(),
        })
    },
    //popups
    getUserOrdersByOrderId(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/user-orders", {
            headers: ApiService.getHeader(),
        });
    },
    getHistoryByOrderId(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/history", {
            headers: ApiService.getHeader(),
        });
    },
    setLabel(orderId, type, entityId, entity){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/labels', { entity : entity, entity_id : entityId, type : type}, {
            headers: ApiService.getHeader(),
        })
    },

    getOnOrderProductsXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/order/products/on-order/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getCreditSkzList(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/payment/skz/list", {
            headers: ApiService.getHeader(),
        });
    },
    getOnlineList(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/payment/online/list", {
            headers: ApiService.getHeader(),
        });
    },
    getTransactionList(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/payment/online/transaction/list", {
            headers: ApiService.getHeader(),
        });
    },
    getCreditSkzApplications(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/payment/skz/applications", {
            headers: ApiService.getHeader(),
        });
    },
    skzApplicationsCreate(orderId, data){
        return ApiService.post(API_OMS_URL+"/order/"+orderId+"/payment/skz/applications/create", data, {
            headers: ApiService.getHeader(),
        })
    },
    skzApplicationsRepeatSms(orderId, data){
        return ApiService.post(API_OMS_URL+"/order/"+orderId+"/payment/skz/applications/repeat-sms", data, {
            headers: ApiService.getHeader(),
        })
    },
    skzApplicationsBefore(orderId){
        return ApiService.post(API_OMS_URL+"/order/"+orderId+"/payment/skz/applications/before/create", {}, {
            headers: ApiService.getHeader(),
        })
    },
    combinePayments(orderId){
        return ApiService.post(API_OMS_URL+"/order/"+orderId+"/payment/combine", {}, {
            headers: ApiService.getHeader(),
        })
    },
    smsLinkPayment(orderId, paymentOrderId){
        return ApiService.post(API_OMS_URL+"/order/"+orderId+"/payment/"+paymentOrderId+"/sms/link", {}, {
            headers: ApiService.getHeader(),
        })
    },
    returnPayments(orderId){
        return ApiService.post(API_OMS_URL+"/order/"+orderId+"/payment/return", {}, {
            headers: ApiService.getHeader(),
        })
    },
    capturePayments(orderId){
        return ApiService.post(API_OMS_URL+"/order/"+orderId+"/payment/capture", {}, {
            headers: ApiService.getHeader(),
        })
    },
    evropochtaItems(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/delivery/evropochta/list",  {
            headers: ApiService.getHeader(),
        })
    },
    evropochtaRequests(orderId){
        return ApiService.get(API_OMS_URL+"/order/"+orderId+"/delivery/evropochta/request/list",  {
            headers: ApiService.getHeader(),
        })
    },
    setWarehouse(orderId, id){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/warehouse/'+id, {}, {
            headers: ApiService.getHeader(),
        })
    },
    getEvropochtaRequestList(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/evropochta/request/list", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getCdekRequestList(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/cdek/request/list", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    updateColumnInAdditionalTables(type, itemId, code, value){
        return ApiService.put(API_OMS_URL+'/order/tables/additional/column/' + itemId, {'type' : type, 'code' : code, 'value' : value}, {
            headers: ApiService.getHeader(),
        })
    },
    getRequestCancel(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/cancel/request/list", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    deactivateCancelBefore(orderId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/cancel/request/deactivate', {}, {
            headers: ApiService.getHeader(),
        })
    },
    getLogisticZnd(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/logistic/znd", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    cancelOrderLogisticZnd(orderId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/logistic/znd/cancel', {}, {
            headers: ApiService.getHeader(),
        })
    },
    createOrderLogisticZnd(orderId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/logistic/znd/create', {}, {
            headers: ApiService.getHeader(),
        })
    },
    updateContactB2bOrder(orderId, payload){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/contact-b2b', {contact : payload}, {
            headers: ApiService.getHeader(),
        })
    },
    deleteLogisticDocument(entity_id, orderId){
        return ApiService.delete(API_OMS_URL+'/order/'+orderId+'/logistic/document/'+ entity_id, {
            headers: ApiService.getHeader(),
        })
    },
    createOrderFinance21Vek(orderId){
        return ApiService.put(API_OMS_URL+'/order/'+orderId+'/payment/finance_21vek/create', {}, {
            headers: ApiService.getHeader(),
        })
    },
    getOrdersFinance21Vek(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/order/payment/finance_21vek/orders", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getRentRequestUsers(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/rent/requestusers", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
}

export default OrderService;