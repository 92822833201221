import Vue from 'vue'
import VueRouter from 'vue-router'
import WithAuthGuard from './guards/with-auth'
import WithoutAuthGuard from './guards/without-auth'

import VueScrollTo from "vue-scrollto";

Vue.use(VueRouter)

const routes = [
    {
        name: "index",
        path: "/",
        component: () => import("@/views/Index"),
        beforeEnter: WithAuthGuard
    },
    {
        name: "auth",
        path: "/auth",
        component: () => import("@/views/Auth"),
        beforeEnter: WithoutAuthGuard
    },
    {
        path: '*',
        name: 'not_found',
        component: () => import("@/views/NotFound")
    },
    {
        name: 'personal_statistics',
        path: '/personal/statistics', component: () => import("@/views/personal/Statistics"),
        meta : {section : 'personal'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'personal_settings',
        path: '/personal/settings', component: () => import("@/views/personal/Settings"),
        meta : {section : 'personal'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'personal_change',
        path: '/personal/change', component: () => import("@/views/personal/ChangePassword"),
        meta : {section : 'personal'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'personal_other',
        path: '/personal/other', component: () => import("@/views/personal/Other"),
        meta : {section : 'personal'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_create',
        path: '/order/create', component: () => import("@/views/order/Create"),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_shipments',
        path: '/order/shipments', component: () => import("@/views/order/Shipments"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_shipments_list',
        path: '/order/shipments/list', component: () => import("@/views/order/ShipmentsList"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_shipments_move_list',
        path: '/order/shipments/move/list', component: () => import("@/views/order/ShipmentsMoveList"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_shipments_return',
        path: '/order/shipments/return', component: () => import("@/views/order/ShipmentsReturn"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'b2b_collect_return',
        path: '/order/b2b/collect/return', component: () => import("@/views/b2b/CollectReturn.vue"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_create_simple',
        path: '/order/create/simple', component: () => import("@/views/order/CreateSimple"),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_buyers',
        path: '/order/buyers', component: () => import("@/views/order/Buyers"),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_consolidation',
        path: '/order/consolidation', component: () => import("@/views/order/Consolidation"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_consolidation_all',
        path: '/order/consolidation/all', component: () => import("@/views/order/ConsolidationAll"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_head_retail',
        path: '/order/head-retail', component: () => import("@/views/order/HeadRetail"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_ready',
        path: '/order/ready', component: () => import("@/views/order/Ready"),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'on_order_products',
        path: '/order/on-order-products', component: () => import("@/views/order/OnOrder"),
        meta : {section : 'order'},
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        beforeEnter: WithAuthGuard,
    },

    {
        name: 'order_list',
        path: '/order/create', component: () => import("@/views/order/List"),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_equipment',
        path: '/order/equipment', component: () => import("@/views/order/Equipment"),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic',
        path: '/order/logistic', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Минску', location : 'minsk', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_brest',
        path: '/order/logistic/brest', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Бресту', location : 'brest', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_gomel_karusel',
        path: '/order/logistic/gomel_karusel', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Гомелю Карусель', location : 'gomel_karusel', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_gomel_kosareva',
        path: '/order/logistic/gomel_kosareva', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Гомелю Косарева', location : 'gomel_kosareva', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_grodno',
        path: '/order/logistic/grodno', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Гродно', location : 'grodno', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_bobruisk',
        path: '/order/logistic/bobruisk', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Бобруйску', location : 'bobruisk', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_baranovichi',
        path: '/order/logistic/baranovichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Барановичи', location : 'baranovichi', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_vitebsk',
        path: '/order/logistic/vitebsk', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Витебску', location : 'vitebsk', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_orsha',
        path: '/order/logistic/orsha', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Орше', location : 'orsha', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_polock',
        path: '/order/logistic/polock', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Полоцку', location : 'polock', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_zhlobin',
        path: '/order/logistic/zhlobin', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Жлобину', location : 'zhlobin', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_mogilev',
        path: '/order/logistic/mogilev', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка по Могилев', location : 'mogilev', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_regions_all',
        path: '/order/logistic/regions/all', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: доставка Все регионы', location : 'regions_all', is_move : false},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move',
        path: '/order/logistic/moving', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Минску', location : 'minsk', is_move : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_baranovichi',
        path: '/order/logistic/moving/baranovichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Барановичи', location : 'baranovichi', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_zhlobin',
        path: '/order/logistic/moving/zhlobin', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Жлобину', location : 'zhlobin', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_orsha',
        path: '/order/logistic/moving/orsha', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Орше', location : 'orsha', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_vitebsk',
        path: '/order/logistic/moving/vitebsk', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Витебску', location : 'vitebsk', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_brest',
        path: '/order/logistic/moving/brest', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Бресту', location : 'brest', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_gomel_karusel',
        path: '/order/logistic/moving/gomel_karusel', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Гомелю (Карусель)', location : 'gomel_karusel', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_gomel_kosareva',
        path: '/order/logistic/moving/gomel_kosareva', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Гомелю (Косарева)', location : 'gomel_kosareva', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_polock',
        path: '/order/logistic/moving/polock', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Полоцку', location : 'polock', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_mogilev',
        path: '/order/logistic/moving/mogilev', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Могилеву', location : 'mogilev', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_bobruisk',
        path: '/order/logistic/moving/bobruisk', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Бобруйску', location : 'bobruisk', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_move_grodno',
        path: '/order/logistic/moving/grodno', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: перемещение по Гродно', location : 'grodno', is_move : true, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_do_doma',
        path: '/order/logistic/dodoma', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: До-дома', location : 'other', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_evropochta',
        path: '/order/logistic/evropochta', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Европочта', location : 'evropochta', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_novogrudok',
        path: '/order/logistic/franchisees/novogrudok', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Новогрудок', location : 'franchisees', 'location_city' : 'novogrudok', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_logoisk',
        path: '/order/logistic/franchisees/logoisk', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Логойск', location : 'franchisees', 'location_city' : 'logoisk', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_pinsk',
        path: '/order/logistic/franchisees/pinsk', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Пинск', location : 'franchisees', 'location_city' : 'pinsk', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_ghodino',
        path: '/order/logistic/franchisees/ghodino', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Жодино', location : 'franchisees', 'location_city' : 'ghodino', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_lida',
        path: '/order/logistic/franchisees/lida', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Лида (Толстого)', location : 'franchisees', 'location_city' : 'lida', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_bereza',
        path: '/order/logistic/franchisees/bereza', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Береза', location : 'franchisees', 'location_city' : 'bereza', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_mozyr',
        path: '/order/logistic/franchisees/mozyr', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Мозырь', location : 'franchisees', 'location_city' : 'mozyr', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_osipovichi',
        path: '/order/logistic/franchisees/osipovichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Осиповичи', location : 'franchisees', 'location_city' : 'osipovichi', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_stolin',
        path: '/order/logistic/franchisees/stolin', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Столин', location : 'franchisees', 'location_city' : 'stolin', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_ivanovo',
        path: '/order/logistic/franchisees/ivanovo', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Иваново', location : 'franchisees', 'location_city' : 'ivanovo', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_pruzhany',
        path: '/order/logistic/franchisees/pruzhany', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Пружаны', location : 'franchisees', 'location_city' : 'pruzhany', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_shchuchin',
        path: '/order/logistic/franchisees/shchuchin', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Щучин', location : 'franchisees', 'location_city' : 'shchuchin', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_olshany',
        path: '/order/logistic/franchisees/olshany', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Ольшаны', location : 'franchisees', 'location_city' : 'olshany', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_gantsevichi',
        path: '/order/logistic/franchisees/gantsevichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Ганцевичи', location : 'franchisees', 'location_city' : 'gantsevichi', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_slonim',
        path: '/order/logistic/franchisees/slonim', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Слоним', location : 'franchisees', 'location_city' : 'slonim', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_malorita',
        path: '/order/logistic/franchisees/malorita', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Малорита', location : 'franchisees', 'location_city' : 'malorita', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_drogichin',
        path: '/order/logistic/franchisees/drogichin', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Дрогичин', location : 'franchisees', 'location_city' : 'drogichin', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_lyakhovichi',
        path: '/order/logistic/franchisees/lyakhovichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Ляховичи', location : 'franchisees', 'location_city' : 'lyakhovichi', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_ivatsevichi',
        path: '/order/logistic/franchisees/ivatsevichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Ивацевичи', location : 'franchisees', 'location_city' : 'ivatsevichi', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_borisov',
        path: '/order/logistic/franchisees/borisov', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Борисов', location : 'franchisees', 'location_city' : 'borisov', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_mariina_gorka',
        path: '/order/logistic/franchisees/mariina_gorka', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Марьина Горка (Восточная)', location : 'franchisees', 'location_city' : 'mariina_gorka', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_mariina_gorka_2',
        path: '/order/logistic/franchisees/mariina_gorka_2', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Марьина Горка (Октябрьская)', location : 'franchisees', 'location_city' : 'mariina_gorka_2', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_zhodino_marta',
        path: '/order/logistic/franchisees/zhodino_marta', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Жодино (Марта)', location : 'franchisees', 'location_city' : 'zhodino_marta', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_smolevichi',
        path: '/order/logistic/franchisees/smolevichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Смолевичи', location : 'franchisees', 'location_city' : 'smolevichi', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_lida_potsdam',
        path: '/order/logistic/franchisees/lida_potsdam', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Лида (Потсдам)', location : 'franchisees', 'location_city' : 'lida_potsdam', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_mikashevichi',
        path: '/order/logistic/franchisees/mikashevichi', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Микашевичи', location : 'franchisees', 'location_city' : 'mikashevichi', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_druzhnyy',
        path: '/order/logistic/franchisees/druzhnyy', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Дружный', location : 'franchisees', 'location_city' : 'druzhnyy', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_cherven',
        path: '/order/logistic/franchisees/cherven', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Червень', location : 'franchisees', 'location_city' : 'cherven', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_all',
        path: '/order/logistic/franchisees/all', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Все франшизы', location : 'franchisees', 'location_city' : 'all', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_franchisees_oshmyany',
        path: '/order/logistic/franchisees/oshmyany', component: () => import("@/views/order/Logistic"),
        props: (route) => ({ filter: route.query.filter }),
        meta : {section : 'logistic', title : 'Логистика: Ошмяны', location : 'franchisees', 'location_city' : 'oshmyany', is_move : false, is_region : true},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_moving',
        path: '/order/moving', component: () => import("@/views/order/Moving"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_return',
        path: '/order/return', component: () => import("@/views/order/Return"),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_problems',
        path: '/order/problems', component: () => import("@/views/order/Problems"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_user',
        path: '/order/user', component: () => import("@/views/order/User"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_transactions_list',
        path: '/order/transactions/list', component: () => import("@/views/order/Transactions.vue"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_all',
        path: '/order/all', component: () => import("@/views/order/All"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_installment',
        path: '/order/installment/all', component: () => import("@/views/order/Installment"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_collect',
        path: '/order/collect', component: () => import("@/views/collect/Ecommerce"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order_collect'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_collect_actual',
        path: '/order/collect/actual', component: () => import("@/views/collect/EcommerceActual.vue"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order_collect'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_collect_retail',
        path: '/order/collect/retail', component: () => import("@/views/collect/Retail.vue"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order_collect'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_collect_retail_actual',
        path: '/order/collect/retail/actual', component: () => import("@/views/collect/RetailActual.vue"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order_collect'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_edit',
        path: '/order/:id', component: () => import("@/views/order/Edit"),
        props: true,
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'shipments_email',
        path: '/email/:hash', component: () => import("@/components/email/Letter"),
        props: true,
        meta : {section : 'shipments_email'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'personal_admin_settings',
        path: '/personal/admin/settings', component: () => import("@/views/personal/admin/Settings"),
        meta : {section : 'personal'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'personal_admin_settings_users',
        path: '/personal/admin/settings/users', component: () => import("@/views/personal/admin/Users"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'personal'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'personal_employees',
        path: '/personal/employees', component: () => import("@/views/personal/Employees"),
        meta : {section : 'personal'},
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        beforeEnter: WithAuthGuard,
    },
    {
        path: '/reports/motivation/return', redirect : '/reports/motivation/return/53'
    },
    {
        path: '/reports/motivation/b2b/return', redirect : '/reports/motivation/b2b/return/53'
    },
    {
        path: '/reports/motivation/b2b', redirect : '/reports/motivation/b2b/53'
    },
    {
        path: '/reports/motivation', redirect : '/reports/motivation/53'
    },
    {
        name: 'reports_motivation',
        path: '/reports/motivation/:store_id', component: () => import("@/components/reports/Motivation"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_motivation_by_department',
        path: '/reports/motivation/by/department', component: () => import("@/components/reports/MotivationByDepartment"),
        props: (route) => ({ filter: route.query.filter}),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_motivation_return',
        path: '/reports/motivation/return/:store_id', component: () => import("@/components/reports/MotivationReturn"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_delivery_price',
        path: '/reports/delivery/price', component: () => import("@/components/reports/DeliveryPrice"),
        props: (route) => ({ filter: route.query.filter}),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports',
        path: '/reports', component: () => import("@/components/reports/All"),
        props: (route) => ({ filter: route.query.filter}),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_user_time',
        path: '/reports/collect/user/time', component: () => import("@/components/reports/CollectUserTime"),
        props: (route) => ({ filter: route.query.filter}),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_employee_interval',
        path: '/reports/collect/employee/interval', component: () => import("@/components/reports/CollectEmployeeInterval"),
        props: (route) => ({ filter: route.query.filter}),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_collect_orders',
        path: '/reports/collect/orders', component: () => import("@/components/reports/CollectOrders"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_collect_transfers',
        path: '/reports/collect/transfers', component: () => import("@/components/reports/CollectTransfers"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_collect_control',
        path: '/reports/collect/control', component: () => import("@/components/reports/CollectControl"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_retail_orders',
        path: '/reports/retail/orders', component: () => import("@/components/reports/RetailOrders"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_ecom_orders',
        path: '/reports/ecom/orders', component: () => import("@/components/reports/EcomOrders"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_orders',
        path: '/reports/orders', component: () => import("@/components/reports/Orders"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_cancellations',
        path: '/reports/cancellations', component: () => import("@/components/reports/Cancellations"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_credit_type',
        path: '/reports/orders/credit_type', component: () => import("@/components/reports/CreditType"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'report_manual_prices',
        path: '/reports/orders/manual_prices', component: () => import("@/components/reports/ManualPrices"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_motivation_by_department_b2b',
        path: '/reports/motivation/by/department/b2b', component: () => import("@/components/reports/b2b/MotivationByDepartment"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'b2b_shipments',
        path: '/b2b/shipments', component: () => import("@/views/b2b/Shipments"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'b2b'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'b2b_collect',
        path: '/b2b/collect', component: () => import("@/views/b2b/Collect"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'b2b'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'b2b_collect_actual',
        path: '/b2b/collect/actual', component: () => import("@/views/b2b/CollectActual"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'b2b'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'ecommerce_2b_collect_actual',
        path: '/ecommerce/b2b/collect/actual', component: () => import("@/views/collect/EcommerceB2bActual"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'b2b'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'ecommerce_b2b_collect',
        path: '/ecommerce/b2b/collect', component: () => import("@/views/collect/EcommerceB2b"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'b2b'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_evropochta_request',
        path: '/order/evropochta/request', component: () => import("@/views/order/Evropochta"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_cdek_request',
        path: '/order/cdek/request', component: () => import("@/views/order/Cdek"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_cancel_request',
        path: '/order/cancel/request', component: () => import("@/views/order/RequestCancel"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_logistic_znd',
        path: '/order/logistic/znd', component: () => import("@/views/order/logistic/Znd"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_motivation_b2b',
        path: '/reports/motivation/b2b/:store_id', component: () => import("@/components/reports/b2b/Motivation"),
        props: (route) => ({ filter: route.query.filter}),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'reports_motivation_b2b_return',
        path: '/reports/motivation/b2b/return/:store_id', component: () => import("@/components/reports/b2b/MotivationReturn.vue"),
        props: (route) => ({ filter: route.query.filter}),
        meta : {section : 'reports'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'sales',
        path: '/sales', component: () => import("@/views/Sales"),
        props: (route) => ({ filter: route.query.filter}),
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'order_payment_finance_21vek',
        path: '/order/payment/finance_21vek/list', component: () => import("@/views/order/payment/Finance21Vek"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
    {
        name: 'rent_request_users',
        path: '/rent/requestusers/', component: () => import("@/views/rent/RequestUsers"),
        props: (route) => ({ filter: route.query.filter, page : route.query.page }),
        meta : {section : 'order'},
        beforeEnter: WithAuthGuard,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            VueScrollTo.scrollTo("body", 500, { offset: savedPosition.y });
            return savedPosition;
        } else {
            VueScrollTo.scrollTo("body");
        }
    },
})

export default router
